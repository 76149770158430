
import { defineComponent, watch } from 'vue';
import chooseBuildRoomInit from '@/methods/building-apt-choose';
import {
    listPagination
} from '@/components/common/list';
import initFace from '@/views/pm/old-community/resident-auth/module/init-face';
import DeleteDialog from '@/components/view/pm/delete-all-dialog';
import FaceImport from '@/components/view/pm/import-face';

export default defineComponent({
    components: {
        listPagination,
        DeleteDialog,
        FaceImport
    },
    setup() {
        const {
            requestData,
            updateList,
            saveParamInPath,
            searchList,
            headers,
            totalCount,
            deleteAll,
            isShowDeleteDialog
        } = initFace();
        const { buildOptions, aptOptions, setRoomOption } = chooseBuildRoomInit();

        // 监听社区选择楼栋，动态改变apt
        watch(() => requestData.value.param.Build, (buildId) => {
            requestData.value.param.Room = 'all';
            setRoomOption(String(buildId));
        });

        return {
            requestData,
            buildOptions,
            aptOptions,
            updateList,
            saveParamInPath,
            searchList,
            headers,
            totalCount,
            deleteAll,
            isShowDeleteDialog
        };
    }
});
